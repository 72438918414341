import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'

export default {
    name: 'NotificationsCreate',
    data() {
        return {
            notification: {
                title: null,
                description: null
            }
        }
    },
    components: {
        adminLayout
    },
    methods: {
        createNotification() {
            const self = this
            let formData = new FormData()

            if(self.notification.title) formData.append('title', self.notification.title)
            if(self.notification.description) formData.append('description', self.notification.description)
            
            httpAxios({
                url: self.$backendUrl + '/api/v1/notifications',
                method: 'POST',
                data: formData
            }).then(function() {
                self.$router.push({ name: 'admin.notifications' })

                self.notifySuccess()
            })
        }
    }
}